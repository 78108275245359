<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :extra-actions="extraActions"
    :inline-actions="false"
    single-edit
    authority="Users_User"
    enable-disable-toggle
    allow-edit-columns
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../userStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      workingGroupsOptions: [],
      userGroupOptions: [],
      memberTypeOptions: [],
      hospitalTypeOptions: [],
      hospitalOptions: [],
      prefectureOptions: [],
      jobPositionOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'users',
        endpoint: 'users',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
        limited: this.$store.getters['app-common/maxUsers'],
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'participant.lastName', label: this.$t('Last Name'), sortable: true },
        { key: 'participant.firstName', label: this.$t('First Name'), sortable: true },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'memberType.title', label: this.$t('Member-Type'), sortable: true },
        { key: 'balance', label: this.$t('Balance'), sortable: true },
        { key: 'memberCode', label: this.$t('Member Code'), visible: false },
        { key: 'subscriptionPrice', label: this.$t('subscriptionPrice'), visible: false, sortable: true },
        { key: 'subscriptionEndDate', label: this.$t('End Date'), visible: false, sortable: true },
        { key: 'workingGroups', label: this.$t('Working Groups'), visible: false },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'enabled', type: 'boolean' },
        { key: 'subscriptionEndDate', type: 'date' },
        { key: 'workingGroups', type: 'list' },
      ]
    },

    filters() {
      return [
        {
          name: 'membertype',
          label: this.$t('Member-Type'),
          options: this.memberTypeOptions,
        },
        {
          name: 'workinggroup',
          label: this.$t('Working Group'),
          options: this.workingGroupsOptions,
        },
        {
          name: 'hospitaltype',
          label: this.$t('Hospital Type'),
          options: this.hospitalTypeOptions,
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$t('Created From'),
          maxDateFieldIdx: '4',
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$t('Created To'),
          minDateFieldIdx: '3',
        },
        {
          name: 'usergroup',
          label: this.$t('User Groups'),
          options: this.userGroupOptions,
        },
        {
          name: 'hospital',
          label: this.$t('hospital'),
          options: this.hospitalOptions,
        },
        {
          name: 'jobposition',
          label: this.$t('JobPosition'),
          options: this.jobPositionOptions,
        },
        {
          name: 'prefecture',
          label: this.$t('prefecture'),
          options: this.prefectureOptions,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
      ]
    },
    extraActions() {
      return [
        {
          endpoint: 'reset-password/email',
          icon: 'SendIcon',
          title: 'Reset Password Email',
          renderCondition: {
            key: 'enabled',
            value: true,
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchMemberTypes')
      .then(response => {
        this.memberTypeOptions = response.data
      })
    this.$store.dispatch('app-common/fetchWorkingGroups')
      .then(response => {
        this.workingGroupsOptions = response.data
      })
    this.$store.dispatch('app-common/fetchUserGroups')
      .then(response => {
        this.userGroupOptions = response.data
      })
    this.$store.dispatch('app-common/fetchHospitalTypes')
      .then(response => {
        this.hospitalTypeOptions = response.data
      })
    this.$store.dispatch('app-common/fetchHospitals')
      .then(response => {
        this.hospitalOptions = response.data
      })
    this.$store.dispatch('app-common/fetchPrefectures')
      .then(response => {
        this.prefectureOptions = response.data
      })
    this.$store.dispatch('app-common/fetchJobPositions')
      .then(response => {
        this.jobPositionOptions = response.data
      })
  },
}
</script>
